import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

function QRImage ({ url }) {
	const [qrData, setQrData] = useState(null);
	useEffect(() => {
		const generateQR = async (url) => {
			try {
				const data = await QRCode.toDataURL(url);
				setQrData(data);
			} catch (err) {
				console.error(err);
			}
		};

		generateQR(url);
	}, [url]);

	return qrData ? <img src={qrData} alt="QR Code" /> : null;
}

export default QRImage;
